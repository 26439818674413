.attendance-table-container {
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

.attendance-table {
  width: 90%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.attendance-table th,
.attendance-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.attendance-table th {
  color: #333;
  font-weight: 400;
}

.attendance-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.attendance-table tr:hover {
  background-color: #f5f5f5;
}

/* Highlight the header row for "COUNT of Today's Attendance" */
.attendance-table thead th {
  color: #000;
}

/* Optional: Add specific styling for the header row if needed */
.attendance-table thead tr:first-child {
  background-color: #8093b3 !important;
}
