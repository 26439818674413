/*
* demo.css
* File include item demo only specific css only
******************************************************************************/
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.menu .app-brand.demo {
  height: 64px;
  margin-top: 12px;
}

.layout-menu.menu .app-brand.demo {
  margin-top: 50px;
}

.app-brand-logo.demo svg {
  width: 22px;
  height: 38px;
}

.app-brand-text.demo {
  /* font-size: 36px; */
  font-size: 30px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  font-weight: 700;
  color: #000;
}
.app-brand-text.demo img {
  width: 100%;
  height: 100%;
  /* padding-left: 130px; */
}

.bg-danger {
  background-color: #fa5757 !important;
}

.text-danger {
  color: #fa5757 !important;
}

.bg-gray {
  background-color: #b8b8b8 !important;
}

.bg-blue-green {
  background-color: #3fb8a9 !important;
}

.text-blue-green {
  color: #3fb8a9 !important;
}

.bg-blue-yellow {
  background-color: #fae31b !important;
}

.text-yellow {
  color: #ff9900 !important;
}

.bg-graytwo {
  background-color: #99b2c6 !important;
}

.bg-orange {
  background-color: #ffac5f !important;
}

.bg-orange-dark {
  background-color: #ff9900 !important;
}

.bg-none {
  background-color: unset !important;
  box-shadow: unset !important;
}

.right-0 {
  right: 0;
}

.bg-black {
  background-color: #000 !important;
}

/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
/* Detached navbar */
.layout-navbar-fixed
  .layout-wrapper:not(.layout-horizontal):not(.layout-without-menu)
  .layout-page {
  padding-top: 76px !important;
}

/* Default navbar */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
  padding-top: 64px !important;
}

/* Navbar page z-index issue solution */
.content-wrapper .navbar {
  z-index: auto;
}
.content-wrapper.store_text h5 {
  color: #2c357d;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
}
/*
* Content
******************************************************************************/

.demo-blocks > * {
  display: block !important;
}

.demo-inline-spacing > * {
  margin: 1rem 0.375rem 0 0 !important;
}

.demo-vertical-spacing > * {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-lg > * {
  margin-top: 1.875rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-lg.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.demo-vertical-spacing-xl > * {
  margin-top: 5rem !important;
  margin-bottom: 0 !important;
}

.demo-vertical-spacing-xl.demo-only-element > :first-child {
  margin-top: 0 !important;
}

.rtl-only {
  display: none !important;
  text-align: left !important;
  direction: ltr !important;
}

[dir="rtl"] .rtl-only {
  display: block !important;
}

/*
* Layout demo
******************************************************************************/

.layout-demo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 1rem;
}

.layout-demo-placeholder img {
  width: 900px;
}

.layout-demo-info {
  text-align: center;
  margin-top: 1rem;
}

.authentication-inner {
  padding-top: 20px;
  padding-right: 75px;
}

.app-brand-logo img {
  max-width: 70px;
  object-fit: cover;
}

.authentication-inner .app-brand {
  padding-bottom: 79px;
}

.authentication-inner h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.003em;
  text-align: left;
  color: #000000;
  padding-bottom: 50px;
}

.authentication-inner .form-label {
  margin-bottom: 18px;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  text-transform: capitalize;
}

.authentication-inner .form-control {
  padding: 0px 27px 0px 0px;
}

/* .form-control {
  display: block;
  width: 100%;
  padding: 0px 27px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.53;
  color: #b8b8b8;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  height: 51px;
  border-radius: 6px;
} */
.line_right {
  border-right: 2px solid #0000001d;
  height: 30px;
  padding-left: 15px;
}

.authentication-inner .card {
  background: unset;
  box-shadow: unset;
}

.border-none {
  border: 0;
}

.authentication-inner .forgot-password {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
}

.authentication-inner .forgot-password a small,
.authentication-inner .forgot-password label {
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.authentication-inner .text-support {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0;
  padding-top: 10px;
}

.login-right {
  /* background: linear-gradient(100.06deg, #000000 31.35%, #2E2E2E 85.26%, #000000 98.28%); */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  border-radius: 22.82px;
  /* height: 1200px; */
  /* position: relative;
  overflow: hidden; */
}

.login-right h2 {
  font-size: 70px;
  line-height: 86px;
  color: #fff;
  font-weight: 700;
  padding-top: 20px;
}

/* .login-page {
  padding: 50px 50px;
} */

.right-img {
  width: 100%;
}

.left-img {
  margin: auto 0px auto auto;
  position: absolute;
  right: 0;
  bottom: 13%;
}

.login-right .text-content {
  padding: 80px;
}

.top-bar .navbar-nav-right {
  width: 100%;
}

.top-bar .search-bar {
  width: 30%;
  box-shadow: 0px 2px 3.6px 0px #2c357d33 !important;
  border-radius: 28px !important;
  /* overflow: hidden; */
  height: 42px;
  position: relative;
}
.search-results {
  box-shadow: 0px 4px 16px 0px #0000001f;
  background: #ffffff;
  border-radius: 12px;
  top: 42px;
  width: 100%;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ff5722 #f1f1f1; /* Thumb and track color for Firefox */
}

.search-result-item {
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.search-result-item:hover {
  background-color: #f0f0f0;
  color: #333;
}

.search-results::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
  height: 8px; /* Scrollbar height for horizontal scrolling */
}

.search-results::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px; /* Optional rounded corners */
}

/* Scrollbar thumb */
.search-results::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #ff5722, #ff8a50); /* Gradient color */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #f1f1f1; /* Optional border around the thumb */
}

/* Hover effect */
.search-results::-webkit-scrollbar-thumb:hover {
  background: #e64a19; /* Darker color on hover */
}

/* Active (when dragging) */
.search-results::-webkit-scrollbar-thumb:active {
  background: #d84315; /* Even darker on active drag */
}

/* Horizontal scrollbar styles (optional) */
.search-results::-webkit-scrollbar-horizontal {
  height: 8px;
}

.search-results::-webkit-scrollbar-thumb-horizontal {
  background: linear-gradient(to right, #ff5722, #ff8a50);
  border-radius: 10px;
}

/* .top-bar .navbar-nav li {
  box-shadow: 0 0.125rem 0.375rem 0 rgba(161, 172, 184, 0.12);
  border-radius: 20px;
} */

.top-bar .navbar-nav li h5 {
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 700;
  color: #2c357d;
}

.top-bar .navbar-nav li a .i {
  font-size: 29px;
  /* border: 0.97px solid #cfd7fd; */
  color: #2c357d;
  padding: 7px 20px;
  width: 40px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
}

.top-bar .navbar-nav .dropdown-toggle {
  cursor: pointer;
  width: 65px;
  height: 65px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar .navbar-nav .avatars {
  position: relative;
  width: 100%;
  height: 54%;
  cursor: pointer;
  border-radius: 100px;
}

.layout-page .navbar {
  margin: 0 -2px;
  box-shadow: 0px 2px 3.6px 0px #2c357d33;
}

.search-bar .i {
  width: 25px;
  color: #b8b8b8;
}

.search-bar .nav-item {
  /* padding: 10px 27px; */
  height: auto;
  background: #c0c0c033;

  border-radius: 28px;
}

/* .search-bar .nav-item input {
  font-size: 18px;
  font-weight: 700;
  color: #b8b8b8;
} */

.cardmorning {
  box-shadow: 0px 1px 15px 0px #b6bacb4d;
  padding: 12px 18px;
  position: relative;
  border-radius: 10px;
  /* width: 26%; */
}

.cardmorning2 {
  box-shadow: 0px 1px 15px 0px #b6bacb4d;
  padding: 15px 18px;
  position: relative;
  border-radius: 10px;
  /* width: 19%; */
}
.cardmorningd {
  box-shadow: 0px 1px 15px 0px #b6bacb4d;
  padding: 30px 30px;
  position: relative;
  border-radius: 10px;
  width: 100%;
}
.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1499px) {
  .cardmorningd {
    flex-wrap: wrap;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .cardmorningd {
    flex: 1 1 calc(50% - 16px); /* 2 items per row */
  }
  .search-bar {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .cardmorningd {
    flex: 1 1 calc(100% - 16px); /* 1 item per row on small screens */
  }
}

.cardmorning h5 {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  color: #fff;
}

.cardmorning h2 {
  font-size: 65.52px;
  font-weight: 700;
  line-height: 65.52px;
  letter-spacing: -0.003em;
  color: #fff;
}

.cardmorning .vector-three {
  position: absolute;
  right: 20px;
  left: auto;
  top: 10px;
}
.text-attendance h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #2c357d;
}

.totalnumber_card {
  /* border: 0.6px solid #2c357d;
  border-radius: 15px;
  padding: 30px 25px;
  box-shadow: 0px 1px 2px 0px #2c357d33; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* flex-direction: column; */
  gap: 25px;

  /* flex-direction: column; */
}

.totalnumber_card.card-img {
  background-image: url(../img/backgrounds/login-vector8.png);
  box-shadow: unset;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 310px;
  background-position: center;
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  border-radius: 19px;
  overflow: hidden;
}

/* .totalnumber_card.card-img2 {
  background-image: url(../img/backgrounds/login-vector9.png);
  box-shadow: unset;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 310px;
  background-position: center;
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  border-radius: 19px;
  overflow: hidden;
} */

.totalnumber_card.card-img h1,
.totalnumber_card.card-img2 h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 84.5px;
  letter-spacing: -0.003em;
  text-align: right;
}
.right_conetent h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #878a99;
}
@media (max-width: 1699px) {
  .right_conetent h4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #878a99;
  }
}
@media (max-width: 1299px) {
  .right_conetent h4 {
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #878a99;
  }
}

/* .totalnumber_card:after {
  content: '';
  background: #626262;
  position: absolute;
  width: 100%;
  height: 39px;
  bottom: 0;
} */

/* .totalnumber_card h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 33px;
  text-align: center;
  color: #2c357d;
  padding-top: 8px;
} */
.min_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.min_left h1 {
  font-size: 35px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #444050;
  margin-bottom: 6px;
}
@media (max-width: 1699px) {
  .min_left h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    color: #444050;
    margin-bottom: 6px;
  }
}

@media (max-width: 1499px) {
  .min_left h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    color: #444050;
    margin-bottom: 6px;
  }
}

.min_right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.parentage_up {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.min_right .parentage_up h5 {
  font-size: 18px;
  font-weight: 700;
  text-align: right;
  color: #34b53a;

  margin-bottom: 0;
}
.min_right .year h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #acaab1;
}
@media (max-width: 1599px) {
  .min_right .year h5 {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    color: #acaab1;
  }
}

.view-iocn {
  width: 44px;
  height: 44px;
  border-radius: 6px;
  /* background: #4a90e233; */

  display: flex;
  align-items: center;
  justify-content: center;
}

.view-iocn i {
  font-size: 18px;
  color: #fff;
}

/* .totalnumber_card:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 94%;
  height: 15px;
  background-color: #626262;
  border-radius: 15px;
  z-index: -1;
  left: 19px;
  bottom: 1px;
} */

.chat-card {
  background-color: #fff;
  border-radius: 19px;
  padding: 28px 18px;
}

.chat-card .card-header {
  margin-bottom: 35px;
}

.chat-card .card-header h5 {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: #2c357d;
}

.chat-card .card-header i {
  font-size: 24px;
  color: #8f8f8f;
}

.chat-card .avatar {
  position: relative;
  /* width: 75px;
  height: 75px; */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.chat-card ul li h4 {
  font-size: 18px;
  color: #2c2c2c;
}

.chat-card ul li small {
  font-size: 15px;
  color: #747474;
  font-weight: 600;
  margin-top: 7px;
}

.chat-card ul li .chat-button {
  padding: 10px;
  padding: 6px;
  border: 0;
  border-radius: 8px;
  color: #252c58;
  font-size: 16.73px;
  font-weight: 500;
  line-height: 25.09px;
  text-align: left;
}

.chat-card .number {
  width: 19px;
  height: 19px;
  border-radius: 100%;

  font-size: 10px;
  font-weight: 700;
  line-height: 11.18px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  margin-bottom: 5px;
  color: #000;
}

.datatables-basic tr th {
  color: #252c589e;
  font-size: 14.73px;
  font-weight: 500;
  line-height: 25.09px;
  text-align: left;
}

.datatables-basic .form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  width: 18.5px !important;
  height: 18.5px !important;
  box-shadow: none;
}

.datatables-basic .odd td {
  font-size: 15px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
  color: #747474;
}

.datatables-basic .odd td i {
  font-size: 16px;
  width: 37.33px;
  height: 37.33px;
  background-color: #f6f6f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datatables-basic .odd td img {
  width: 66px;
  height: 66px;
  border-radius: 17px;
  object-fit: cover;
}

.datatables-basic.black-text-table tr td small {
  font-size: 12px;
  font-weight: 500;
  color: #747474;
  line-height: 12px;
}

.datatables-basic.black-text-table tr td {
  font-size: 15px;
  font-weight: 700;
  color: #000000;
}

/* .datatables-basic.table > :not(:first-child) {
  border-top: 0px solid #d9dee3;
} */

.see-details-button {
  background-color: #2c2c2c;
  border-radius: 7px;
  padding: 4.5px 9px;
  color: #fff;
  border: 0;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
}

.status {
  border-radius: 7px;
  padding: 8px 9px;
  color: #fff;
  border: 0;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 103.7px;
  display: block;
  text-align: center;
}

.administrator-dropdown {
  background: linear-gradient(
    201.14deg,
    #0a0a0a 19.5%,
    rgba(10, 10, 10, 0.71) 175.76%
  );
  border-radius: 19px;
  /* min-height: 559px; */
  min-height: 582px;
  position: relative;
  padding: 55px;
  overflow: hidden;
}

.administrator-dropdown.vector-img {
  background-image: url(../img/backgrounds/login-vector7.png);
  background-repeat: no-repeat;
  background-position: center;
  min-height: 496px;
  margin-top: 44px;
}

.vector-fore {
  position: absolute;
  bottom: 0;
}

.administrator-dropdown h3 {
  font-size: 26px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.administrator-dropdown h2 {
  font-size: 78.55px;
  font-weight: 800;
  line-height: 96.35px;
  letter-spacing: -0.003em;
  text-align: center;
  color: #fff;
}

.administrator-dropdown h6 {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  color: #b8b8b8;
}

.dropdown-menu h6 {
  font-size: 14px;
  font-weight: 700;
  text-align: end;
  box-shadow: 0px 4px 25px 0px #f1f4fa;
  background: unset;
  margin-bottom: 5px !important;
  width: fit-content;
  margin: auto 0 auto auto;
  color: #545454;
  margin-top: 12px;
}

/* .dropdown-menu .dropdown-menu-end .notifications{
  position:absolute;
  left: -200px;
} */

.layout-navbar .navbar-dropdown .dropdown-menu {
  min-width: 22rem;
  overflow: hidden;
}

.layout-navbar .navbar-dropdown .dropdown-menu {
  min-width: 22rem;
  overflow: hidden;
}

.notifications {
  min-width: 550px;
  padding: 30px;
  border-radius: 18px;
}

.dropdown-header h3 {
  font-size: 38.48px;
  font-weight: 700;
  line-height: 50.32px;
  text-align: left;
  color: #000000;
}

.dropdown-header h6 {
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  margin-left: 20px;
  color: #000000;
}

.notifications .chat-card {
  margin-top: 30px;
}

.w-px-15 {
  width: 15px;
}

.h-px-15 {
  height: 15px;
}

.groww .bg-blue-green {
  color: #3fb8a9;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.003em;
  text-align: center;
  background-color: #0cffe254 !important;
  margin-bottom: 0;
  padding: 7px 8px;
}

.groww .bg-danger {
  color: #fa5757;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.003em;
  text-align: center;
  background-color: #fa575747 !important;
  margin-bottom: 0;
  padding: 7px 8px;
}

.total-patients {
  margin-top: 70px;
  z-index: 1;
  position: relative;
}

.progress-chat {
  display: grid;
  gap: 50px;
}

.progress-item {
  display: flex;
  align-items: center;
  gap: 20px;
}

.progress-item .progress-contect .items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-item .progress-contect .items h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  text-align: left;
  color: #000;
  margin-bottom: 0;
}

.progress-item .progress-contect .items h4 {
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  text-align: right;
  margin-bottom: 0;
  color: #000;
}

.progress-contect {
  max-width: 40%;
  width: 100%;
}

.progress-item .progress-bar {
  box-shadow: none !important;
  height: 17px;
}

.progress-item .progress {
  height: 17px;
  background-color: #f4f0f0;
  border-radius: 20px;
}

.sales-dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 40px;
}

.sales-dashboard h2 {
  font-size: 38.48px;
  font-weight: 700;
  line-height: 50.32px;
  text-align: left;
  color: #0b0b0b;
}

.sales-dashboard .modal-button,
.export,
.sales-dashboard a {
  font-size: 14px;
  font-weight: 700;
  color: #fff !important;
  background-color: #000;
  padding: 12.5px 32px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.export i {
  color: #fff !important;
  font-size: 14px !important;
}

.kids-card {
  text-align: center;
}

.kids-card h3 {
  font-size: 18px;
  color: #000000;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700;
}

.parents-modal .modal-title {
  font-size: 38.48px;
  font-weight: 700;
  line-height: 50.32px;
  text-align: left;
  color: #0b0b0b;
  margin-bottom: 30px;
}

.parents-modal .form-label {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  text-transform: capitalize;
}

.parents-modal form {
  padding: 1.5rem 1.5rem 0.25rem;
}

.parents-modal input {
  background-color: #fafafa;
  height: 56px;
  color: #b8b8b8;
  border-radius: 6px;
  border: 0;
}

.parents-modal .form-select {
  background-color: #fafafa;
  height: 56px;
  color: #000000;
  border-radius: 6px;
  border: 0;
  font-size: 16px;
  font-weight: 700;
}

.parents-modal textarea {
  background-color: #fafafa;
  color: #000000;
  border-radius: 6px;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  height: 219px;
  padding: 27px;
}

.parents-modal textarea:focus {
  background-color: #fafafa;
  color: #000000;
  border-radius: 6px;
  border: 0 !important;
  font-size: 16px;
  font-weight: 500;
}

.parents-modal input:focus {
  box-shadow: none;
  background-color: #fafafa;
}

.parents-modal .chat-card img {
  max-width: 72px;
  max-height: 72px;
  object-fit: cover;
}

.parents-modal .chat-card h3 {
  font-size: 12px;
  color: #000000;
  margin-top: 8px;
  font-weight: 700;
  text-align: center;
}

.parents-modal #drop-area {
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  border-radius: 6px;
  min-height: 254px;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  width: 100%;
}

.parents-modal.guardian-modal #drop-area {
  min-height: 180px;
}

.parents-modal .drop-text {
  font-size: 15px;
  color: #b8b8b8;
  font-weight: 600;
}

.parents-modal .drop-icon .i {
  color: #ababab;
  font-size: 4.5rem;
}

.parents-modal .btn-primary {
  height: 63px;
  border-radius: 20px;
  line-height: 63px;
}

.parents-modal .kids-card {
  cursor: pointer;
  /* opacity: 0.1; */
  transition: border-color 0.3s;
}

.parents-modal .kids-card.selected {
  opacity: 1;
}

.parents-modal {
  max-width: 1400px;
  width: 100%;
}

.parents-modal .modal-content {
  padding: 50px;
}

.table-search-bar {
  background-color: #fff;
  padding-left: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 23px;
  color: #252c58;
  border: 1.2px solid #d5d9dd;
  font-weight: 700;
  width: 88%;
  height: 48px;
  top: 443px;
  left: 627px;
  gap: 9px;
  border-radius: 12px;
  opacity: 1px;
}

.table-search-bar i {
  font-size: 20px;
  color: #b8b8b8;
}

.table-search-bar input {
  background-color: unset;
  padding: 0;
}

.table-search-bar input:focus {
  background-color: unset;
  padding: 0;
  box-shadow: unset !important;
}
.calendar_pad .calendar input {
  background: #c0c0c033;
  border: 2px solid #d5d9dd;
  padding: 10px 10px;
  width: 100%;
  height: 48.07px;
  top: 443.04px;
  left: 1506px;
  gap: 0px;
  border-radius: 12px;

  color: #252c58;
}

.calendar_pad .calendar {
  position: relative;
}
.calenderClear {
  position: absolute;
  top: 10px;
  right: 33px;
}
.filter {
  background: #2c357d40;
  /* width: 200px; */
  /* padding: 10px 60px; */
  top: 443px;
  left: 1685.96px;
  gap: 0px;
  border-radius: 12px;
  opacity: 1px;
  width: 14%;
  padding: 10px;
}

.filter .calendar h4 {
  color: #2c357d;
  font-size: 16.82px;
  font-weight: 300;
  line-height: 25.23px;
  text-align: left;
}
.calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
/* @media (max-width: 1699px) {
.calendar img {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
} */
.kidsselect {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 40px;
}
.kidsselect h5 {
  font-size: 20px;
  font-weight: 500;
  color: #2c357d;
}

.kidsselect .form-select {
  background-color: #fafafa;
  width: 139px;
  height: 59px;
  padding: 0px 27px 0px 27px;
  border: 0;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}

.last-years {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: center;
}

.last-years h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: right;
  color: #b8b8b8;
  margin-bottom: 0;
}

.load-more {
  text-align: center;
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-top: 20px;
}

.profile-img {
  min-height: 386px;
  border-radius: 19px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.profile-img .i {
  /* width: 49px;
  height: 49px; */
  width: 36px;
  height: 37px;
  padding: 8px;
  border-radius: 20px;
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #202020;
  position: absolute;
  left: auto;
  right: 20px;
  top: 20px;
}

.profile-card {
  background-color: #fff;
  border-radius: 26px;
  padding: 33px;
  margin-top: -50px;
  z-index: 1;
  position: relative;
}

.profile-card .pro-img {
  width: 215px;
  height: 215px;
  border-radius: 50%;
  border: 6px solid #ffffff;
  object-fit: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-card .pro-img img {
  border-radius: 50%;
}

.profile-card .pro-img .i {
  width: 47px;
  height: 42px;
  padding: 10px;
  border-radius: 30px;
  font-size: 20px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: absolute;
  left: auto;
  right: -27px;
  top: 32px;
}

.profile-top {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: absolute;
  top: -150px;
  left: 30px;
  width: 96%;
}

.save {
  width: 140px;
  height: 47px;
  border-radius: 20px;
  background-color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #fff;
}

.cancel {
  width: 140px;
  height: 47px;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: #99b2c6;
}

.profile-card .parents-modal {
  max-width: 100%;
  margin-top: 100px;
}

.tab-bars {
  margin-top: 70px;
}

.chat-card .tab-buttons {
  display: flex;
  gap: 13px;
  justify-content: center;
}

.chat-card .tab-button {
  padding: 10px;
  cursor: pointer;
  border: none;
  transition: background 0.3s;
}

.chat-card .tab-button {
  border-radius: 20px;
  padding: 13px 45px;
  font-size: 18px;
  font-weight: 700;
  border: none;
  transition: background 0.3s;
  cursor: pointer;
  color: #99b2c6;
  background: unset;
  gap: 20px;
  display: flex;
  align-items: center;
}

.chat-card .tab-item {
  display: none;
  padding: 10px;
}

.chat-card .tab-item.active {
  display: block;
}

.chat-card .tab-button.active {
  background: #202020;
  color: #ffffff;
  /* margin-left: 5rem; */
}

.chat-card .tab-content {
  padding: 0px;
  border-radius: 0px;
  margin-top: 40px;
}

.chat-card .tab-content .chat-button {
  border-radius: 19px !important;
  width: 85px;
  text-align: center;
}

.tab-container.password-card {
  background: #f1f7fb;
  border-radius: 1px;
}

.tab-container.password-card .parents-modal input {
  background-color: #ffffff;
}

.tab-container.password-card {
  padding: 50px;
}

.notification-card {
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #e6e6e6;
}

.notification-card h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  color: #000000;
  margin-bottom: 0;
}

.notification h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  color: #000;
  margin-bottom: 30px;
}

.notification-button {
  display: flex;
  gap: 40px;
}

.switch-button .switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 20px;
  background: #dfd9ea;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
}

.switch-button .switch::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 22px;
  height: 22px;
  background: #fafafa;
  border-radius: 50%;
  transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    background 0.28s cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.switch-button .switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
}

.switch-button input:checked + .switch {
  background: #000000;
}

.switch-button input:checked + .switch::before {
  left: 27px;
  background: #fff;
}

.switch-button input:checked + .switch:active::before {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(0, 150, 136, 0.2);
}

#layout-menu {
  /* position: fixed; */
  top: 0;
  left: 0;
  /* width: 308px; */
  /* height: 100vh; */
  /* overflow-y: auto;
  overflow-x: hidden; */
}
.navbar-nav .dropdown-menu {
  position: absolute;
}

.btn-dark:hover {
  transform: none !important;
}
/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

/* @font-face {
  font-family: Poppins-Regular;
  src: url("../fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("../fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("../fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("../fonts/poppins/Poppins-SemiBold.ttf");
} */

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

/*---------------------------------------------*/
a {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  /* color: #fff; */
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  color: #2c357d;
}
.right_content h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: #878a99;
}
@media (max-width: 1799px) {
  .right_content h4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #878a99;
  }
}
@media (max-width: 1599px) {
  .right_content h4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #878a99;
  }
}
p {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  /* list-style-type: none; */
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

/* textarea:focus,
input:focus {
  border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}
input:focus:-moz-placeholder {
  color: transparent;
}
input:focus::-moz-placeholder {
  color: transparent;
}
input:focus:-ms-input-placeholder {
  color: transparent;
} */

/* textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
} */

input::-webkit-input-placeholder {
  color: #fff;
}
input:-moz-placeholder {
  color: #fff;
}
input::-moz-placeholder {
  color: #fff;
}
input:-ms-input-placeholder {
  color: #fff;
}

textarea::-webkit-input-placeholder {
  color: #fff;
}
textarea:-moz-placeholder {
  color: #fff;
}
textarea::-moz-placeholder {
  color: #fff;
}
textarea:-ms-input-placeholder {
  color: #fff;
}

label {
  margin: 0;
  display: block;
}

/*---------------------------------------------*/
/* button {
  outline: none !important;
  border: none;
  background: transparent;
  color: #fff;
} */

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-size: 18px;
  color: #e5e5e5;
  line-height: 6.5;
}

/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}
input.error {
  border-color: red;
}
.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #2c357d 100%);

  position: relative;
  z-index: 1;
}

/* .container-login100::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
} */

/* .wrap-login100 {
  width: 500px;
  border-radius: 10px;
  overflow: hidden;
  padding: 55px 55px 37px 55px;

  background: #9152f8 !important;
  background: -webkit-linear-gradient(top, #7579ff, #b224ef) !important;
  background: -o-linear-gradient(top, #7579ff, #b224ef) !important;
  background: -moz-linear-gradient(top, #7579ff, #b224ef) !important;
  background: linear-gradient(top, #7579ff, #b224ef) !important;
} */
.wrap-login100 {
  width: 500px;
  overflow: hidden;
  /* padding: 55px 55px 37px 55px;
  background: linear-gradient(to bottom, #7579ff, #b224ef); */
}

/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
  width: 100%;
}
/* 
.login100-form-logo {
  font-size: 60px;
  color: #333333;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
}

.login100-form-title {
  font-size: 30px;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;

  display: block;
} */

/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
  width: 100%;
  position: relative;
}

.input100 {
  font-size: 16px;
  color: #fff;
  line-height: 1.2;

  display: block;
  width: 100%;
  height: 45px;
  border-radius: 1px;
  border: 1px solid #2c357d;
}

/*---------------------------------------------*/
.focus-input100 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input100::before {
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #fff;
}

.focus-input100::after {
  font-size: 22px;
  color: #fff;

  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 6px;
  left: 0px;
  padding-left: 5px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus {
  padding-left: 5px;
}

.input100:focus + .focus-input100::after {
  top: -22px;
  font-size: 18px;
}

.input100:focus + .focus-input100::before {
  width: 100%;
}

.has-val.input100 + .focus-input100::after {
  top: -22px;
  font-size: 18px;
}

.has-val.input100 + .focus-input100::before {
  width: 100%;
}

.has-val.input100 {
  padding-left: 5px;
}

/*==================================================================
[ Restyle Checkbox ]*/

.contact100-form-checkbox {
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 35px;
}

.input-checkbox100 {
  display: none;
}

.label-checkbox100 {
  font-size: 14px;
  color: #2c357d;
  font-weight: 300;
  line-height: 1.2;

  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
}

.label-checkbox100::before {
  content: "";
  font-size: 14px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: #2c357d;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.input-checkbox100:checked + .label-checkbox100::before {
  color: #555555;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login100-form-btn {
  font-size: 16px;
  color: #555555;
  line-height: 1.2;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 120px;
  height: 50px;
  border-radius: 25px;

  background: #9152f8;
  background: -webkit-linear-gradient(bottom, #7579ff, #b224ef);
  background: -o-linear-gradient(bottom, #7579ff, #b224ef);
  background: -moz-linear-gradient(bottom, #7579ff, #b224ef);
  background: linear-gradient(bottom, #7579ff, #b224ef);
  position: relative;
  z-index: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background-color: #fff;
  top: 0;
  left: 0;
  opacity: 1;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  color: #fff;
}

.login100-form-btn:hover:before {
  /* opacity: 0; */
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 55px 15px 37px 15px;
  }
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
  position: relative;
}

.alert-validate::before {
  content: attr(data-validate);
  position: absolute;
  max-width: 70%;
  background-color: #fff;
  border: 1px solid #c80000;
  border-radius: 2px;
  padding: 4px 25px 4px 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0px;
  pointer-events: none;

  color: #c80000;
  font-size: 13px;
  line-height: 1.4;
  text-align: left;

  visibility: hidden;
  /* opacity: 0; */

  /* -webkit-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  transition: opacity 0.4s; */
}

.alert-validate::after {
  content: "\f12a";
  font-size: 16px;
  color: #c80000;

  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}

.alert-validate:hover:before {
  visibility: visible;
  /* opacity: 1; */
}
table td,
.table th {
  vertical-align: middle !important  ;
}
@media (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

/* // */
.css-13ol44q {
  width: 1100px !important;
}
.css-q3wnbe-MuiResponsiveChart-container {
  width: 90% !important;
  height: 475px !important;
}
/* .css-rrbjrf-MuiChartsSurface-root .MuiBarElement-root {
  width: 35px !important;
} */
