/* selector.css */

.selector_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.selector_label {
  margin-bottom: 0;
  font-weight: 400;
  padding-left: 10px;
}
.dropdown_icon {
  transition: transform 0.3s;
}

.dropdown_icon.open {
  transform: rotate(180deg);
}
.custom_selector {
  padding: 8px;
  font-size: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  border-bottom: 1px solid rgba(44, 53, 125, 0.15) !important;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2c357d;
}

.custom_dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
}

.custom_option {
  padding: 8px;
  cursor: pointer;
  /* border-bottom: 1px solid #2c357d; */
}

.custom_option:hover {
  background-color: #2c357d;
  color: white;
}

.css-b62m3t-container {
  width: 100% !important;
}
.css-1rfhp43-control {
  border: none !important;
}

.search_container {
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.search_input {
  width: 100%;
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.search_input:focus {
  outline: none;
  border-color: #2c357d;
}

.no_options {
  padding: 8px;
  color: #666;
  text-align: center;
}
.search_container {
  padding: 8px;
}

.custom_dropdown {
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  z-index: 1000;
  overflow-y: auto; /* Enable scrollbar if content exceeds height */
}

/* Ensure custom options respect the height in non-search mode */
.custom_option {
  padding: 8px;
}
