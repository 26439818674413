.status-btn {
  padding: 4px 8px; /* adjust as needed */
  border-radius: 4px; /* adjust as needed */
}

.status-Present {
  background-color: #d4edbc;
}

.status-Absent {
  background-color: #ff0000;
}

.status-Half-Day {
  background-color: #c27ba0;
}

.status-On-Leave {
  background-color: #6fa8dc;
}

.status-Late {
  background-color: #e69138;
}

.status-Weekly-Off {
  background-color: #f1c232;
}
